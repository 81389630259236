import {
  Button,
  Center,
  Grid,
  Stack,
  Text,
  TextInput,
  Title
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";
import API from "../api";
import { Layout } from "../components/layout";

export default function Unsubscribe() {
  const api = new API();
  const [loading, setLoading] = useState(false);
  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      email: "",
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  return (
    <Center>
      <Layout>
        <Stack w={{ base: "100%", xs: 500, sm: 700 }} ta={"center"}>
          <Title lh={1.6}>
            <span
              style={{
                color: "var(--mantine-color-coral-3)",
              }}
            >
             Goodbye
            </span>{" "}
            to Daily Wisdom 🥹
          </Title>
          <Text>
            We're sad to see you go! If you unsubscribe, you will no longer
            receive the daily advice emails at 9:00 AM. We hope our advice has
            been as essential as your morning coffee. If you ever change your
            mind, you can always re-subscribe and continue receiving our daily
            wisdom.
          </Text>
          <Stack pt={"xl"} gap={"xs"}>
            <Title order={3}></Title>
            <form
              onSubmit={form.onSubmit((values) =>{
                setLoading(true);
                api.unsubscribe(values.email).finally(()=>{
                  setLoading(false)
                })}
              )}
            >
              <Grid justify="center" align="start">
                <Grid.Col span={{base:12,xs:8,md:9}}>
                  <TextInput
                    {...form.getInputProps("email")}
                    flex={"1 auto"}
                    size="md"
                    placeholder="john@doe.com"
                  />
                </Grid.Col>
                <Grid.Col span={{base:12,xs:4, md:3}}>
                  <Button loading={loading} fullWidth type="submit" size="md">
                    Unsubscribe
                  </Button>
                </Grid.Col>
              </Grid>
            </form>
          </Stack>
        </Stack>
      </Layout>
    </Center>
  );
}
