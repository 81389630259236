import { Center, CheckIcon, Loader, Stack, Title } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import API from "../api";
import { Layout } from "../components/layout";

enum StatusType {
  VERIFIED,
  NOT_VERIFIED,
  LOADING,
}

export default function VerifyPage() {
  const { token } = useParams();
  const [status, setStatus] = useState(StatusType.LOADING);
  const [message, setMessage] = useState("Verifying...");
  const [verified, setVerified] = useState(false);
  const api = new API();
  if (token && !verified) {
    api
      .verify(token)
      .then((res) => {
        if (res.ok) {
          return res.json()
        } else {
          switch (res.status) {
            case 400:
              setMessage(
                "The verification link you used is invalid or has expired. Please request a new verification email and try again."
              );
              break;
            default:
              setMessage(
                "A problem occurred while completing your verification. Please try again later."
              );
              break;
          }
          setStatus(StatusType.NOT_VERIFIED);
        }
      }).then((res)=>{
        switch (res.purpose) {
          case "SUBSCRIBE":
            setMessage(
              "You have successfully subscribed to daily wisdom. Welcome aboard! 🎉"
            );
            break;
          case "UNSUBSCRIBE":
            setMessage(
              "You have successfully unsubscribed to daily wisdom. Goodbye! 🥹"
            );
            break;
          default:
            break;
        }

        setStatus(StatusType.VERIFIED);
        return;
      })
      .catch(() => {
        setMessage(
          "A problem occurred while completing your verification. Please try again later."
        );
        setStatus(StatusType.NOT_VERIFIED);
      }).finally(()=>{
        setVerified(true);
      });
  }

  const body = () => {
    switch (status) {
      case StatusType.LOADING:
        return (
          <>
            <Loader size={75} />
            <Title ta={"center"} order={4}>{message}</Title>
          </>
        );
      case StatusType.NOT_VERIFIED:
        return (
          <>
            <IconX
              size={100}
              style={{
                border: "3px solid var(--mantine-color-red-filled)",
                padding: "15px",
                borderRadius: 100,
                color: "var(--mantine-color-red-filled)",
              }}
            />
            <Title ta={"center"} order={4}>{message}</Title>
          </>
        );
      case StatusType.VERIFIED:
        return (
          <>
            <CheckIcon
              size={100}
              style={{
                border: "3px solid green",
                padding: "15px",
                borderRadius: 100,
                color: "green",
              }}
            />
            <Title ta={"center"} order={4}>{message}</Title>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <Layout>
      {
        <Center>
          <Stack justify="center" align="center">
            {body()}
          </Stack>
        </Center>
      }
    </Layout>
  );
}
