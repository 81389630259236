import {
  Button,
  Center,
  Grid,
  Select,
  Stack,
  Text,
  TextInput,
  Title
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";
import API from "../api";
import { Layout } from "../components/layout";
import timezones from "../utils/timezones";

export default function Home() {
  const api = new API();
  const [loading, setLoading] = useState(false);
  // const [advice, setAdvice] = useState("");
  // const [randomButtonLoading, setRandomButtonLoading] = useState(false);
  // const adviceHandler = () => {
  //   setRandomButtonLoading(true)
  //   api.randomAdvice().then((res) => {
  //     setAdvice(res);
  //   }).finally(()=>{
  //     setRandomButtonLoading(false)
  //   });
  // };
  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      email: "",
      timezone: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      timezone: (value) =>
        timezones.includes(value) ? null : "Select a timezone",
    },
  });

  return (
    <Center>
      <Layout>
        <Stack gap={"xl"} w={{ base: "100%", xs: 500, sm: 700 }} ta={"center"}>
          <Stack gap={"xs"}>
            <Title lh={1.6}>
              Start Your Day with{" "}
              <span
                style={{
                  color: "var(--mantine-color-pine-green-9)",
                }}
              >
                Daily Wisdom
              </span>
            </Title>
            <Text>
              At 9:00 AM every morning, you’ll get an advice that’s as essential
              as your coffee (or so we like to think). Ready to make mornings
              more interesting?
            </Text>
          </Stack>

          <Stack gap={"xs"}>
            <Title order={3}>Sign Up for the Daily Delight</Title>
            <form
              onSubmit={form.onSubmit((values) => {
                setLoading(true);
                api.subscribe(values.email, values.timezone).finally(() => {
                  setLoading(false);
                });
              })}
            >
              <Grid justify="center" align="start">
                <Grid.Col span={{ base: 12, md: 5 }}>
                  <TextInput
                    {...form.getInputProps("email")}
                    flex={"1 auto"}
                    size="md"
                    placeholder="john@doe.com"
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, xs: 8, md: 4 }}>
                  <Select
                  defaultValue={Intl.DateTimeFormat().resolvedOptions().timeZone}
                    {...form.getInputProps("timezone")}
                    searchable
                    size="md"
                    placeholder="Select your timezone"
                    data={timezones}
                  />
                </Grid.Col>

                <Grid.Col span={{ base: 12, xs: 4, md: 3 }}>
                  <Button loading={loading} fullWidth type="submit" size="md">
                    Subscribe
                  </Button>
                </Grid.Col>
              </Grid>
            </form>
          </Stack>
          {/* <Divider/> */}
          {/* <Stack gap={"xs"}>
             <Title order={4}>Try it Out!</Title> 
            <Button onClick={adviceHandler} size="sm" variant="light">
            Click to try and get random advice!
            </Button>
            <Paper style={{alignItems:"center",display:"flex", justifyContent:"center"}} withBorder bg={"transparent"} mih={120}>
              {randomButtonLoading ? <Loader/> :<Text>{advice}</Text>}
            
            </Paper>
          </Stack>
          */}
        </Stack>
      </Layout>
    </Center>
  );
}
