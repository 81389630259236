import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import VerifyPage from "./pages/Verify";
import Unsubscribe from "./pages/Unsubscribe";
const RouteConfig = (): JSX.Element => {
  return (
    <Routes>
      <Route path={"*"} element={<Home />} />
      <Route path={"unsubscribe"} element={<Unsubscribe />} />
      <Route path={"verify/:token"} element={<VerifyPage />} />
    </Routes>
  );
};
export default RouteConfig;
