import {
  ActionIcon,
  AppShell,
  Button,
  Center,
  Group,
  Image,
  Popover,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { IconQuestionMark } from "@tabler/icons-react";
import logo from "../assets/logo.svg";
import classes from "../styles/logo.module.css";
export function Layout({ children }: { children: JSX.Element }) {


  return (
    <AppShell header={{ height: 60 }} padding="md" withBorder={false}>
      <AppShell.Header
        style={{
          backgroundColor: "var(--mantine-color-light-blue-0)",
          boxShadow: "0px 1px 10px 0px var(--mantine-color-gray-6)",
        }}
      >
        <Group justify="space-between" h="100%" px="md" wrap="nowrap">
          <Group wrap="nowrap" align="center" gap={"xs"}>
            {/* <Title ta={"center"} order={2}>
              dailise
            </Title> */}
            <Image
              onClick={() => {
                window.location.href = "https://dailise.tscburak.dev";
              }}
              classNames={classes}
              w={100}
              src={logo}
            />
            <Popover width={350} position="bottom-start" withArrow shadow="md">
              <Popover.Target>
                <ActionIcon variant="outline" radius={"xl"} size={"sm"}>
                  <IconQuestionMark />
                </ActionIcon>
              </Popover.Target>
              <Popover.Dropdown>
                <Stack gap={5}>
                  <Title order={6}>Advices</Title>
                  <Text size="xs">
                    Powered by the{" "}
                    <a
                      style={{ color: "var(--mantine-color-pine-green-9)" }}
                      href="https://api.adviceslip.com"
                    >
                      api.adviceslip.com
                    </a>
                    , we offer a collection of millions of sarcastic and
                    semi-serious tips to brighten your day. Whether you need a
                    laugh or a little nudge in the right direction, dailise has
                    got you covered!
                  </Text>
                </Stack>
              </Popover.Dropdown>
            </Popover>
          </Group>

          <Group wrap="nowrap" gap={0}>
            <Button
              component="a"
              href=""
              variant="transparent"
              color={"var(--mantine-text-color)"}
            >
              <u>Subscribe</u>
            </Button>
            <Button
              component="a"
              href="#unsubscribe"
              variant="transparent"
              color={"var(--mantine-text-color)"}
            >
              <u>Unsubscribe</u>
            </Button>
          </Group>
        </Group>
      </AppShell.Header>
      <AppShell.Main>
        <Stack
          style={{ justifyContent: "space-between" }}
          mih={"calc(100vh - 60px - var(--mantine-spacing-xl))"}
        >
          {children}
          <Center
            style={{
              fontStyle: "italic",
              color: "var(--mantine-color-charcoal-4)",
            }}
            p={"xs"}
          >
            Developed and designed by&nbsp;
            <a
              style={{ color: "var(--mantine-color-pine-green-9)" }}
              target="_blank"
              href="https://tscburak.dev"
              rel="noreferrer"
            >
              tscburak
            </a>
          </Center>
        </Stack>
      </AppShell.Main>
      {/* <AppShell.Footer
        style={{
          backgroundColor: "var(--mantine-color-light-blue-0)",
          color: "var(--mantine-color-charcoal-9)",
        }}
      >
        
      </AppShell.Footer> */}
    </AppShell>
  );
}
