import {
  createTheme,
  CSSVariablesResolver,
  MantineProvider,
} from "@mantine/core";
import "@mantine/core/styles.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
const theme = createTheme({
  /** Put your mantine theme override here */
  primaryColor: "pine-green",
  primaryShade: 9,
  components: {
    TextInput: {
      styles: {
        input: {
          backgroundColor: "transparent",
        },
      },
    },
    Select: {
      styles: {
        input: {
          backgroundColor: "transparent",
        },
      },
    },
  },
  colors: {
    "light-blue": [
      "#e6fcff",
      "#d6f4f8",
      "#ade8f0",
      "#82dce8",
      "#61d1e0",
      "#4dcbdc",
      "#3ec7db",
      "#2fb0c2",
      "#1c9dad",
      "#008898",
    ],
    "pine-green": [
      "#ebfffd",
      "#d7fdf9",
      "#a9fdf2",
      "#7bfdec",
      "#5efde8",
      "#50fde4",
      "#48fde3",
      "#3be1c9",
      "#2bc8b2",
      "#00ad99",
    ],
    "coral": [
      "#ffe9e7",
      "#ffd2ce",
      "#ffa29b",
      "#ff7164",
      "#fe4637",
      "#fe2c19",
      "#ff1d09",
      "#e40e00",
      "#cb0500",
      "#b10000",
    ],
    "lemon": [
      "#fffde6",
      "#fffad0",
      "#fff59f",
      "#ffef69",
      "#ffeb40",
      "#ffe828",
      "#ffe61c",
      "#e3cc0f",
      "#c9b500",
      "#ad9c00",
    ],
    "charcoal": [
      "#f3f5f7",
      "#e8e8e8",
      "#cccecf",
      "#adb4b8",
      "#939ea3",
      "#839097",
      "#788993",
      "#667780",
      "#596a73",
      "#475c66",
    ],
  },
  other: {
    "text-color": "#424242",
  },
});
const resolver: CSSVariablesResolver = (theme) => ({
  variables: {
    "--mantine-text-color": theme.other["text-color"],
  },
  light: {
    "--mantine-color-deep-orange": theme.other.deepOrangeLight,
  },
  dark: {
    "--mantine-color-deep-orange": theme.other.deepOrangeDark,
  },
});
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <MantineProvider theme={theme} cssVariablesResolver={resolver}>
      <Notifications />
      <HashRouter>
        <App />
      </HashRouter>
    </MantineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
