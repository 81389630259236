import { notifications } from "@mantine/notifications";

export default class API {
  private BASE_URL =
    process.env.NODE_ENV === "development"
      ? "http://localhost:8000/"
      : "https://api.dailise.tscburak.dev/";
  private autoClose = 10000;

  public async subscribe(email: string, timezone: string) {
    return await fetch(this.BASE_URL + "subscription/subscribe", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        email,
        timezone,
      }),
    })
      .then((res) => {
        let color = "red";
        let title = "";
        let message = "";
        if (res.ok) {
          color = "teal";
          title = "Need to Verify";
          message =
            "One more step for daily wisdom: Please verify your subscription via the email we just sent. It may take a moment to arrive, so don't forget to check your spam and other folders. 📩";
        } else {
          switch (res.status) {
            case 400:
              title = "Already Subscribed";
              message =
                "You are already subscribed to daily wisdom. No further action is needed.";
              break;
            case 409:
              title = "Verification mail is already sent.";
              message =
                "You need to wait 30 seconds before get another verification mail.";
              break;
            default:
              title = "We Could Not Process Your Request";
              message =
                "A problem occurred while completing your request. Please try again later.";
              break;
          }
        }

        notifications.show({
          color,
          title,
          message,
          autoClose: this.autoClose,
        });
      })
      .catch(() => {
        notifications.show({
          color: "red",
          title: "A Problem Occurred",
          message:
            "A problem occurred while completing your request. Please try again later.",
          autoClose: this.autoClose,
        });
      });
  }

  public async unsubscribe(email: string) {
    return await fetch(this.BASE_URL + "subscription/unsubscribe", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify({
        email,
      }),
    })
      .then((res) => {
        let color = "red";
        let title = "";
        let message = "";
        if (res.ok) {
          color = "teal";
          title = "Need to Verify";
          message =
            "One more step to complete your unsubscription: Please verify your request via the email we just sent. It may take a moment to arrive, so don't forget to check your spam and other folders. 📩";
        } else {
          switch (res.status) {
            case 400:
              title = "Subscription Not Found";
              message =
                "We couldn't find your subscription to dailise. It looks like you are already unsubscribed or never subscribed. No further action is needed.";
              break;
            case 409:
              title = "Verification mail is already sent.";
              message =
                "You need to wait 30 seconds before get another verification mail.";
              break;
            default:
              title = "We Could Not Process Your Request";
              message =
                "A problem occurred while completing your request. Please try again later.";
              break;
          }
        }

        notifications.show({
          color,
          title,
          message,
          autoClose: this.autoClose,
        });
      })
      .catch(() => {
        notifications.show({
          color: "red",
          title: "A Problem Occurred",
          autoClose: this.autoClose,
          message:
            "A problem occurred while completing your request. Please try again later.",
        });
      });
  }

  public async verify(token: string) {
    return await fetch(this.BASE_URL + "subscription/verify", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify({
        token,
      }),
    })
  }

  public async randomAdvice() {
    return await fetch("https://api.adviceslip.com/advice")
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          notifications.show({
            color: "red",
            title: "A Problem Occurred",
            message:
              "A problem occurred while completing your request. Please try again later.",
            autoClose: this.autoClose,
          });
        }
      })
      .then((res) => {
        return res.slip.advice;
      })
      .catch(() => {
        notifications.show({
          color: "red",
          title: "A Problem Occurred",
          message:
            "A problem occurred while completing your request. Please try again later.",
          autoClose: this.autoClose,
        });
      });
  }
}
